.cart {
  padding: 0 30px;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    margin-bottom: 30px;
  }

  &__header {
    padding: 24.8px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #deddd8;
    border-bottom: 1px dashed #0000004a;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__header-action-buttons {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
    }
  }
  &__header--flex { display: flex;}
  &__header--space-between { justify-content: space-between;}
  &__header--center { align-items: center;}
  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      //padding: 0 30px;
      flex-direction: column-reverse;
    }
  }
  &__footer {
    padding: 0 60px 90px 60px;
    background-color: #deddd8;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0 30px;
    }
  }

  &__title {
    margin: 0;
    @include fontSize(32);
    @include lineHeight(32, 32);
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-bottom: 15px;
      @include fontSize(22);
      @include lineHeight(22, 22);
    }
  }

  &__main {
    width: 100%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding-right: unset;
      width: 100%;
    }
  }
  &__aside {
    width: 25%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding-right: unset;
      width: 100%;
    }
  }

  .back-to-products {
    color: blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__mobile-cta {
    padding: 0 30px 30px 30px;
  }

  &__empty {
    padding: 30px;
    text-align: center;
  }

  &__coupon-wrapper {
    display: flex;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-top: 10px;
      flex-direction: column;
      width: 100%;
    }
  }
  &__active-coupon {
    margin-right: 30px;
    display: flex;
    align-items: center;

    @include media("<576px") {
      margin-right: 0;
      margin-bottom: 15px;
      justify-content: space-between;
    }

    .active-coupon-title {
      margin-right: 15px;
    }
    .active-coupon {
      display: flex;
    }
    .active-coupon-name {
      padding: 8px 20px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top: solid 2px #712E30;
      border-left: solid 2px #712E30;
      border-bottom: solid 2px #712E30;
      border-left: solid 2px #712E30;
      background-color: #ffffff;
      color: #712E30;
      font-weight: 600;
    }
    .active-coupon-remove {
      padding: 8px 10px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top: solid 2px #712E30;
      border-left: solid 2px #712E30;
      border-bottom: solid 2px #712E30;
      border-right: solid 2px #712E30;
      background-color: #ffffff;
      color: #712E30;
      font-weight: 500;
      display: block;
      cursor: pointer;

      &:hover {
        color: #ffffff;
        background-color: #283B4B;
        border: solid 2px #283B4B;
      }
    }
  }
}

.js-cupon-code-input {
  display: none;
  height: 38px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  text-indent: 10px;
}

.show {
  display: inline-block;
}

.cart-table {
  &__header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
  &__column-item-title {
    width: 38%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
    }
  }
  &__column-quantity {
    width: 10%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 20%;
    }
  }
  &__column-item-price {
    width: 20%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: none;
    }
  }
  &__column-total-price {
    width: 20%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: none;
    }
  }
  &__column-actions {
    width: 12%;
    text-align: right;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: none;
    }
  }

  &__body {}
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
  &__item--even {
    background-color: #efefee;
  }
  &__item--odd {
    background-color: #deddd8;
  }
  &__item--key-0 {
    background-color: #efefee;
  }
  &__item-column-item-title {
    padding-left: 30px;
    width: 35%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-bottom: 15px;
      padding-left: 0;
      width: 100%;
    }

    a {
      color: $appRed100;
      text-decoration: none;
      font-weight: 500;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        @include fontSize(26);
        @include lineHeight(28, 26);
        font-weight: 600;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__item-column-quantity {
    width: 15%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-bottom: 15px;
      width: 100%;
    }

    .t {
      margin-bottom: 10px;

      @include media("≥1400px") {}

      @include media("≥1200px") {}

      @include media("≥992px") {}

      @include media("≥768px") {}

      @include media("≥576px") {}

      @include media("<576px") {
        margin-bottom: 5px;
      }
    }

    input {
      width: 32px;
      font-weight: 600;
      text-align: center;
      border-radius: 0;
      border-width: 1px;
      border-color: $appC4;
      font-size: 1em;
    }
  }
  &__item-column-item-price {
    width: 20%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-bottom: 15px;
      width: 100%;
    }

    .t {
      margin-bottom: 10px;

      @include media("≥1400px") {}

      @include media("≥1200px") {}

      @include media("≥992px") {}

      @include media("≥768px") {}

      @include media("≥576px") {}

      @include media("<576px") {
        margin-bottom: 5px;
      }
    }
    .b {
      @include fontSize(20);
      @include lineHeight(24, 20);
      font-weight: 500;
    }
  }
  &__item-column-total-price {
    width: 20%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      width: 100%;
      text-align: left;
    }

    .t {
      margin-bottom: 10px;
    }
    .b {
      @include fontSize(20);
      @include lineHeight(24, 20);
      font-weight: 600;
    }
  }
  &__item-column-actions {
    width: 10%;
    text-align: left;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-top: 10px;
      width: 100%;
      text-align: left;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    text-align: right;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      align-items: start;
      margin-top: 10px;
      flex-direction: column;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .cart-table-item {
    display: flex;

    &__image {
      position: relative;
      margin: 0 50px;

      @include media("≥1400px") {}

      @include media("≥1200px") {}

      @include media("≥992px") {}

      @include media("≥768px") {}

      @include media("≥576px") {}

      @include media("<576px") {
        margin: 0 50px 0 0;
      }

      img {
        position: relative;
        z-index: 2;
      }
    }

    &__title {
      margin-top: 0;
      @include fontSize(20);
      @include lineHeight(24, 20);
      font-weight: 500;

      a {
        color: $appBlack100;
        text-decoration: none;
        transition: all 300ms 0s ease-in-out;

        &:hover {
          color: #a29061;
        }
      }
    }
  }
}

.number-input {
  display: flex;

  .minus,
  .plus {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $appC4;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
}

.cart-total {
  &__text {
    margin-bottom: 5px;
    @include fontSize(20);
    @include lineHeight(20, 20);
  }
  &__value {
    @include fontSize(34);
    @include lineHeight(34, 34);
  }
}

.cart-total-with-delivery {
  &__text {
    @include fontSize(20);
    @include lineHeight(26, 20);
  }
  &__value {
    margin-bottom: 20px;
    @include fontSize(34);
    @include lineHeight(34, 34);
  }
}

.mobile-cart-actions {
  display: none;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    display: block;
  }
}

.checkout {
  margin-bottom: 30px;
  padding: 0 30px;
}
